import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LocaleProvider } from "@wa/werkstoff-data-table";

export default function DataTableLocaleProvider({ children }) {
  const { t } = useTranslation();

  const locale = useMemo(
    () => ({
      "dateRangeFilter.start": t("Startdatum", { ns: "table" }),
      "dateRangeFilter.end": t("Enddatum", { ns: "table" }),
      "numberRangeFilter.from": t("Von", { ns: "table" }),
      "numberRangeFilter.to": t("Bis", { ns: "table" }),
      "stringValuesFilter.selectAll": t("Alle auswählen", { ns: "table" }),
      "stringValuesFilter.search": t("Elemente suchen…", { ns: "table" }),
      "filter.reset": t("Filter zurücksetzen", { ns: "table" }),
      "columnPopover.selectAll": t("Alle auswählen", { ns: "table" }),
      "toolbar.selected": ({ count }) =>
        t("{{count}} ausgewählt", { ns: "table", count }),
      "pagination.rowsPerPage": t("Einträge pro Seite", { ns: "table" }),
      "pagination.displayedRows": ({ from, to, count }) =>
        t(`{{from}}-{{to}} von {{count}}`, { from, to, count, ns: "table" }),
    }),
    [t]
  );

  return <LocaleProvider locale={locale}>{children}</LocaleProvider>;
}
