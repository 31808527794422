import React, { useState } from "react";
import {
  Collapse,
  List,
  ListItemSecondaryAction,
  Popover,
} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Buffer,
  TagTextOutline,
  History,
  ChevronRight,
  Cog as Settings,
  Warehouse,
  NfcVariant,
  TrayFull,
} from "mdi-material-ui";
import cx from "classnames";
import { useBooleanLocalStorageState } from "../../../util/useLocalStorageState";
import { EnhancedTooltip } from "../../../components/TooltipIconButton";
import ComponentExtensionPoint from "../../plugins/ComponentExtensionPoint";
import { useRegistry } from "../../plugins/registry";
import { useTranslation } from "react-i18next";
import { useFeatures, useUser } from "../context/applicationContext";

const styles = (theme) => ({
  root: {
    overflowY: "auto",
    overflowX: "hidden",
  },
  menu: {
    width: 232,
    transition: theme.transitions.create("width"),

    "@media (min-width: 600px, max-width: 959px)": {
      width: "calc(100vw - 64px)",
      maxWidth: 320, // matches most Google apps
    },

    "@media (max-width: 599px)": {
      width: "calc(100vw - 56px)",
      maxWidth: 320, // matches most Google apps
    },

    "&$collapsed": {
      width: 56,
    },
  },
  itemText: {
    fontSize: "14px",
    color: theme.palette.text.secondary,
  },
  selectedItem: {
    backgroundColor: "rgba(0, 0, 0, 0.05) !important", // same as Inbox

    "& $itemText, & $icon": {
      color: theme.palette.primary.main,
    },
  },
  listItem: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,

    "& span": {
      fontWeight: 500,
    },

    "&:not($selectedItem):hover": {
      background: "rgba(0, 0, 0, 0.03)", // same as Inbox
    },
  },
  nestedMenu: {
    "& $listItem": {
      paddingLeft: 32,
    },
  },
  toggleChevron: {
    position: "absolute",
    left: 192,
    height: 24,
    "& svg": {
      transition: theme.transitions.create("transform"),
    },
    "&:not($collapsed) svg": {
      transform: "rotate(90deg)",
    },
  },
  collapseItem: {
    "&:focus": {
      background: "transparent",
    },
  },
  collapseIcon: {
    "& svg": {
      transition: theme.transitions.create("transform"),
    },
    "&:not($collapsed) svg": {
      transform: "rotate(180deg)",
    },
  },
  icon: {}, // don't remove this, it's used above
  collapsed: {}, // don't remove this, it's used above
});

function NavigationDrawer({
  classes,
  activeRoute,
  onChangeRoute,
  additionalStockItemTypes,
  additionalMenuItems,
  additionalSettingsMenuItems,
}) {
  const { t } = useTranslation();
  const { pages } = useRegistry();
  const user = useUser();
  const features = useFeatures();

  const menuItems = [
    {
      name: t("Bestand"),
      icon: <Buffer />,
      route: "Stock",
    },
    ...additionalStockItemTypes,
    {
      name: t("Artikel"),
      icon: <TagTextOutline />,
      route: "Articles",
    },
    {
      name: t("Historie"),
      icon: <History />,
      route: "Log",
    },
    ...additionalMenuItems,
  ];

  const settingsMenuItems = [
    { name: t("Lagerplätze"), icon: <Warehouse />, route: "towers" },
    {
      name: t("Ladungsträger", { context: "plural" }),
      icon: <TrayFull />,
      route: "loadCarriers",
      hidden: !features.loadCarriers,
    },
    {
      name: t("RFID-Tags"),
      icon: <NfcVariant />,
      route: pages.some((p) => p.route === "rfidTags") ? "rfidTags" : null,
    },
    ...additionalSettingsMenuItems,
  ];

  const [collapsed, setCollapsed] = useBooleanLocalStorageState(
    "skl-menu-collapsed",
    false
  );

  const settingsAnchorEl = React.useRef();
  const [showSettingsPopover, setShowSettingsPopover] = useState(false);
  const [showSettings, setShowSettings] = useBooleanLocalStorageState(
    "skl-settings-collapsed",
    false
  );

  const settingsMenuItem = (
    <MenuItem
      dense={false}
      classes={{
        root: classes.listItem,
        selected: classes.selectedItem,
      }}
      button
      data-collapsed={collapsed}
      buttonRef={settingsAnchorEl}
      onClick={() => {
        if (collapsed) {
          setShowSettingsPopover(true);
        } else {
          setShowSettings(!showSettings);
        }
      }}
    >
      <ListItemIcon classes={{ root: classes.icon }}>
        <Settings />
      </ListItemIcon>
      <ListItemText
        primary={t("Einstellungen")}
        classes={{ primary: classes.itemText }}
      />
      {!collapsed && (
        <ListItemSecondaryAction
          className={cx(classes.toggleChevron, {
            [classes.collapsed]: !showSettings,
          })}
          onClick={() => setShowSettings((s) => !s)}
        >
          <ListItemIcon classes={{ root: classes.icon }}>
            <ChevronRight />
          </ListItemIcon>
        </ListItemSecondaryAction>
      )}
    </MenuItem>
  );

  const settingsMenuContent = settingsMenuItems
    .filter((item) => !item.hidden)
    .map(
      ({ name, icon, route, tooltip, Component = MenuItem, ...other }, i) => (
        <Component
          key={i}
          selected={route != null && activeRoute === route}
          classes={{
            root: classes.listItem,
            selected: classes.selectedItem,
          }}
          dense={false}
          button
          onClick={() => {
            if (route !== activeRoute) onChangeRoute(route);
          }}
          data-collapsed={collapsed}
          disabled={route == null}
          {...other}
        >
          <ListItemIcon classes={{ root: classes.icon }}>{icon}</ListItemIcon>
          <ListItemText
            primary={name}
            classes={{ primary: classes.itemText }}
          />
        </Component>
      )
    );

  return (
    <div className={classes.root}>
      <List className={cx(classes.menu, { [classes.collapsed]: collapsed })}>
        {menuItems.map(
          (
            { name, icon, route, tooltip, Component = MenuItem, ...other },
            i
          ) => {
            const menuItem = (
              <Component
                key={i}
                selected={activeRoute === route}
                classes={{
                  root: classes.listItem,
                  selected: classes.selectedItem,
                }}
                button
                dense={false}
                onClick={() => {
                  if (route !== activeRoute) onChangeRoute(route);
                }}
                data-collapsed={collapsed}
                {...other}
              >
                <ListItemIcon classes={{ root: classes.icon }}>
                  {icon}
                </ListItemIcon>
                <ListItemText
                  primary={name}
                  classes={{ primary: classes.itemText }}
                />
              </Component>
            );
            return collapsed ? (
              <EnhancedTooltip
                key={i}
                title={
                  (typeof tooltip === "function" ? tooltip({ t }) : tooltip) ??
                  (typeof name === "function" ? name({ t }) : name)
                }
                placement="right"
              >
                {menuItem}
              </EnhancedTooltip>
            ) : (
              menuItem
            );
          }
        )}
        {user?.role === "admin" && (
          <>
            {collapsed ? (
              <>
                <EnhancedTooltip title={t("Einstellungen")} placement="right">
                  {settingsMenuItem}
                </EnhancedTooltip>
                <Popover
                  open={showSettingsPopover && collapsed}
                  anchorEl={settingsAnchorEl.current}
                  onClose={(e) => setShowSettingsPopover(false)}
                  anchorOrigin={{ horizontal: "right", vertical: "top" }}
                  transformOrigin={{ horizontal: "left", vertical: "top" }}
                  onClick={() => setShowSettingsPopover(false)}
                >
                  <List>{settingsMenuContent}</List>
                </Popover>
              </>
            ) : (
              settingsMenuItem
            )}
            {!collapsed && (
              <Collapse in={showSettings} className={classes.nestedMenu}>
                {settingsMenuContent}
              </Collapse>
            )}
          </>
        )}
        <MenuItem
          dense={false}
          onClick={() => {
            setCollapsed(!collapsed);
            setShowSettings(false);
          }}
          className={cx(classes.listItem, classes.collapseItem)}
          disableRipple
        >
          <ListItemIcon
            classes={{
              root: cx(classes.icon, classes.collapseIcon, {
                [classes.collapsed]: collapsed,
              }),
            }}
          >
            <ChevronRight />
          </ListItemIcon>
        </MenuItem>
        <ComponentExtensionPoint
          name="navigationDrawer"
          innerProps={{ collapsed }}
          variant="list"
        />
      </List>
    </div>
  );
}

export default withStyles(styles)(NavigationDrawer);
