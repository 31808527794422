import React, { useCallback, useEffect } from "react";
import {
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  withStyles,
} from "@material-ui/core";
import { getAuthConfig, getAccessToken } from "../api";
import useLocalStorageState from "../../../util/useLocalStorageState";
import { Trans, useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import { Web } from "mdi-material-ui";
import ButtonDialogTitle from "../../../components/ButtonDialogTitle";
import AccessKeyForm from "./authentication/AccessKey";
import UserForm from "./authentication/User";

const styles = (theme) => ({
  title: {
    display: "flex",
    "& h6": {
      color: theme.palette.primary.dark,
    },
    "& > *": {
      fontWeight: 400,
    },
  },
  light: {
    fontWeight: 300,
  },
  flex: {
    flex: 1,
  },
  languageSelector: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    "& > svg": { width: 18, height: 18, opacity: 0.54, marginRight: 8 },
  },
  employee: {
    width: 250,
    margin: `${theme.spacing.unit * 3}px auto 0`,
    display: "block",
  },
});

function SetupDialog({
  classes,
  onSetEmployee,
  authStatus,
  onAuthStatusChange,
  ...other
}) {
  const { t } = useTranslation();
  const [employee, setEmployee] = useLocalStorageState("skl-employee");
  const [newEmployee, setNewEmployee] = React.useState("");
  const [authConfig, setAuthConfig] = React.useState(null);
  const [authenticationError, setAuthenticationError] = React.useState(false);
  const [fixedEmployee, setFixedEmployee] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (other.open) {
      setAuthenticationError(false);
    }
  }, [other.open]);

  React.useEffect(() => {
    const loadAuthConfig = async () => {
      setLoading(true);
      try {
        const authConfig = await getAuthConfig(
          localStorage.getItem("skl-access-token")
        );
        setAuthConfig(authConfig);
        if (!authConfig.enabled || authConfig.isAuthenticated) {
          setFixedEmployee(!!authConfig.user?.employee);
          onAuthStatusChange("authenticated", authConfig.user);
        } else {
          onAuthStatusChange("unauthenticated", null);
        }
      } catch (e) {
        // TODO
      } finally {
        setLoading(false);
      }
    };
    if (authConfig == null) {
      loadAuthConfig();
    }
  }, [authConfig, onAuthStatusChange]);

  useEffect(() => {
    if (authStatus === "unauthenticated" && employee == null) {
      // explicitly logged out
      setEmployee(null);
    }
  }, [authStatus, employee, setEmployee]);

  const handleAuthentication = useCallback(
    async (credentials) => {
      setLoading(true);
      try {
        setEmployee(null);
        setNewEmployee("");
        const { accessToken, user } = await getAccessToken(credentials);
        localStorage.setItem("skl-access-token", accessToken);
        onAuthStatusChange("authenticated", user);
        setFixedEmployee(!!user.employee);
      } catch (e) {
        console.error(e);
        setAuthenticationError(true);
      } finally {
        setLoading(false);
      }
    },
    [setEmployee, onAuthStatusChange]
  );

  return (
    <Dialog disableBackdropClick fullWidth maxWidth="sm" {...other}>
      <ButtonDialogTitle
        className={classes.title}
        button={
          <div className={classes.languageSelector}>
            <Web />
            <LanguageSelector disableUnderline />
          </div>
        }
      >
        StoreKeeper <span className={classes.light}>Smart</span>
      </ButtonDialogTitle>
      {(authStatus === "unauthenticated" ||
        employee != null ||
        fixedEmployee) && (
        <>
          {authConfig?.methods?.[0] === "accessKey" && (
            <AccessKeyForm
              loading={loading}
              onSubmit={handleAuthentication}
              authenticationError={authenticationError}
            />
          )}
          {authConfig?.methods?.[0] === "user" && (
            <UserForm
              loading={loading}
              onSubmit={handleAuthentication}
              authenticationError={authenticationError}
            />
          )}
        </>
      )}
      {authStatus === "authenticated" && employee == null && !fixedEmployee && (
        <>
          <DialogContent>
            <DialogContentText color="textPrimary" variant="body2">
              <Trans>
                Sie können Ihr Mitarbeiterkürzel eingeben, damit Ihnen die
                Lagervorgänge zugeordnet werden. Eine Änderung ist jederzeit im
                oberen Bereich möglich.
              </Trans>
            </DialogContentText>
            <TextField
              className={classes.employee}
              label={t("Mitarbeiterkürzel")}
              placeholder={t('z.B. "dp"')}
              margin="dense"
              variant="filled"
              value={newEmployee}
              onChange={(e) => setNewEmployee(e.target.value)}
              autoFocus
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  onSetEmployee(newEmployee);
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            {newEmployee === "" ? (
              <Button onClick={other.onClose}>{t("Überspringen")}</Button>
            ) : (
              <Button
                color="primary"
                onClick={() => {
                  onSetEmployee(newEmployee);
                }}
              >
                {t("Weiter", { context: "setup" })}
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default withStyles(styles)(SetupDialog);
