import { commonCreateArticleFields } from "../../../articles/components/articleForm";

export default {
  ...commonCreateArticleFields({ allowDecimals: true }),
  "coil.material": {
    defaultValue: ""
  },
  "coil.length": {
    defaultValue: null,
    error: length => !isNaN(length) && length <= 0,
    convert: value => parseFloat(value) || null, // input -> value
    convertBack: value => value || "" // value -> input
  },
  "coil.width": {
    defaultValue: null,
    error: width => width <= 0,
    convert: value => parseFloat(value) || null, // input -> value
    convertBack: value => value || "" // value -> input
  },
  "coil.thickness": {
    defaultValue: null,
    error: thickness => !isNaN(thickness) && thickness <= 0,
    convert: value => parseFloat(value) || null, // input -> value
    convertBack: value => value || "" // value -> input
  },
  "coil.weight": {
    defaultValue: null,
    error: specificWeight =>
      !isNaN(specificWeight) && parseFloat(specificWeight) <= 0,
    convert: value => parseFloat(value) || null, // input -> value
    convertBack: value => value || "" // value -> input
  }
};
