import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ApolloProvider } from "react-apollo";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import { MuiThemeProvider } from "@material-ui/core/styles";
import AppFrame from "../components/Frame";
import Articles from "../../articles";
import Stock from "../../stock";
import Log from "../../log";
import Towers from "../../towers/Towers";
import { createApolloClient, getCurrentUser } from "../api";
import { useRegistry } from "../../plugins/registry";
import { SklContextProvider } from "../context/applicationContext";
import theme from "../components/theme";
import LoadCarriers from "../../loadCarriers/LoadCarriers";
import DataTableLocaleProvider from "../context/DataTableLocaleProvider";
import EnhancedSnackbar from "../components/EnhancedSnackbar";
import { useTranslation } from "react-i18next";

function AppFrameContainer(props) {
  const { i18n } = useTranslation();
  const registry = useRegistry();
  const [authStatus, setAuthStatus] = useState("unknown");

  const [route, setRoute] = useState({
    route: "Stock",
    selectedPage: "Stock",
  });

  const handleChangeRoute = (route) => {
    const [selectedPage, match] = route.split("/", 2);
    setRoute({ route, selectedPage, match });
  };

  const pluginPages = useMemo(() => {
    const pluginPagesMap = {};
    for (const { route, component } of registry.pages) {
      pluginPagesMap[route] = component;
    }
    return pluginPagesMap;
  }, [registry]);

  const ActivePage = {
    Articles,
    Log,
    Stock,
    towers: Towers,
    loadCarriers: LoadCarriers,
    ...pluginPages,
  }[route.selectedPage];

  const AppWrapper = registry.getAppWrapper();

  const [apolloClient] = useState(() =>
    createApolloClient({
      onAuthError: () => {
        setAuthStatus("unauthenticated");
      },
    })
  );

  const [user, setUser] = useState();
  useEffect(() => {
    if (authStatus === "authenticated") {
      let stale = false;
      getCurrentUser()
        .then((user) => {
          if (!stale) {
            setUser(user);
          }
        })
        .catch((e) => {
          console.error("Could not get current user", e);
        });
      return () => {
        stale = true;
      };
    }
  }, [authStatus]);

  const handleChangeAuthStatus = useCallback(
    (authStatus, user) => {
      setAuthStatus(authStatus);
      setUser(user);
      if (user?.preferredLanguage) {
        i18n.changeLanguage(user?.preferredLanguage);
      }
    },
    [i18n]
  );

  const handleLogout = useCallback(() => {
    localStorage.removeItem("skl-access-token");
    setAuthStatus("unauthenticated");
    setUser(null);
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ApolloProvider client={apolloClient}>
          <SnackbarProvider
            snackbarProps={{ autoHideDuration: 10000 }}
            SnackbarComponent={EnhancedSnackbar}
          >
            <DataTableLocaleProvider>
              <SklContextProvider route={route} user={user}>
                <AppWrapper>
                  <AppFrame
                    activeRoute={route.route}
                    onChangeRoute={handleChangeRoute}
                    authStatus={authStatus}
                    onAuthStatusChange={handleChangeAuthStatus}
                    onLogout={handleLogout}
                    {...props}
                  >
                    {authStatus === "authenticated" && (
                      <ActivePage match={route.match} />
                    )}
                  </AppFrame>
                </AppWrapper>
              </SklContextProvider>
            </DataTableLocaleProvider>
          </SnackbarProvider>
        </ApolloProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

export default AppFrameContainer;
