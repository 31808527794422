import React, { createRef } from "react";
import {
  withStyles,
  Popover,
  TableRow,
  Menu,
  MenuItem,
  ListSubheader,
  Divider,
  FormControlLabel,
  Checkbox,
  ListItemIcon,
  Typography,
  MenuList,
} from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Check, Filter, FilterOutline, TableArrowDown } from "mdi-material-ui";
import EnhancedTable from "@wa/werkstoff-data-table";
import withLocalStorageConfig from "@wa/werkstoff-data-table/lib/withLocalStorageConfig";
import withFixedColumns from "@wa/werkstoff-data-table/lib/withFixedColumns";
import withVerticalScroll from "@wa/werkstoff-data-table/lib/withVerticalScroll";
import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import cx from "classnames";
import debounce from "debounce";
import ChangeStockForm from "./ChangeStockForm";
import { withRegistry } from "../../plugins/registry";
import TooltipIconButton from "../../../components/TooltipIconButton";
import PopoverIconButton from "../../../components/PopoverIconButton";
import TableExportForm from "./TableExportForm";
import { saveCsv, saveXlsx } from "../../../util/spreadsheetExport";
import { withSnackbar } from "material-ui-snackbar-provider";
import {
  compartmentColumn,
  ifStockItem,
  loadCarrierColumn,
  noteColumn,
  towerColumn,
  maybeAggregated,
  emptyToBottom,
} from "./stockTable";
import ComponentExtensionPoint from "../../plugins/ComponentExtensionPoint";
import ChangeTemporaryLocationForm from "../containers/ChangeTemporaryLocationForm";
import SearchTextField from "../../../components/SearchTextField";
import DraggablePaper from "../../../components/DraggablePaper";
import ChangeLocationForm from "../containers/ChangeLocationForm";
import MoveLoadCarrierForm from "../containers/MoveLoadCarrierForm";
import CreateStockItemForm from "./CreateStockItemForm";
import TablePlaceholder from "../../../components/TablePlaceholder";
import { withTranslation } from "react-i18next";
import { prepareSmartSearch } from "../../../util/smartSearch";

const StockTable = withLocalStorageConfig()(
  withFixedColumns(2)(withVerticalScroll()(EnhancedTable))
);

const stockItemNoteColumn = (other, { t }) => {
  const column = noteColumn({
    defaultTitle: (index) =>
      t("Bestands-Notiz {{number}}", { number: index + 1 }),
    note: maybeAggregated((row) => {
      switch (other.noteIndex) {
        case 0:
          return row.note;
        case 1:
          return row.note2;
        case 2:
          return row.note3;
        default:
          return null;
      }
    }),
    itemId: (row) => row.id,
    type: "stockItem",
    ...other,
  });
  return {
    ...column,
    content: ifStockItem(column.content),
    contentString: ifStockItem(column.contentString),
    tooltip: ifStockItem(column.tooltip),
  };
};

const articleNoteColumn = (other, { t }) =>
  noteColumn({
    defaultTitle: (index) =>
      t("Artikel-Notiz {{number}}", { number: index + 1 }),
    note: (row) => {
      switch (other.noteIndex) {
        case 0:
          return row.note;
        case 1:
          return row.note2;
        case 2:
          return row.note3;
        default:
          return null;
      }
    },
    itemId: (row) => row.article.id,
    type: "article",
    ...other,
  });

const styles = (theme) => ({
  root: {
    padding: "24px 24px 14px",
    flex: 1,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  toolbar: {
    marginBottom: 16,
    display: "flex",
    flexDirection: "row",
  },
  search: {
    width: 350,
  },
  flex: {
    flex: 1,
  },
  toolbarButton: {
    margin: "-4px 0",
    padding: 10,
  },
  deltaPopover: {
    padding: 16,
    marginTop: -8,
    marginLeft: 24,
    minWidth: 450,
    "&$popoverDragging": {
      transition: "opacity 355ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
    },
    "& > *:first-child": {
      cursor: "default",
    },
  },
  popoverDragging: {},
  popover: {
    padding: 8,
  },
  exportPopover: {
    padding: 8,
    width: 400,
  },
  aggregateExport: {
    margin: "0 -6px",
  },
  minimumStockRow: {
    "& > td:first-child": {
      background: `linear-gradient(to right, ${red[500]} 0, ${red[500]} 4px, #fff 4px)`,
    },
    "&:hover > td:first-child": {
      // don't let the table's row hover style overwrite the red border
      background: `linear-gradient(to right, ${red[500]} 0, ${
        red[500]
      } 4px, ${fade(
        theme.palette.type === "light"
          ? "rgba(0, 0, 0, 0.07)"
          : "rgba(255, 255, 255, 0.14)",
        0
      )} 4px, ${fade(
        theme.palette.type === "light"
          ? "rgba(0, 0, 0, 0.07)"
          : "rgba(255, 255, 255, 0.14)",
        0
      )}) !important`,
    },
  },
  menuHeader: {
    outline: "none",
    height: 32,
    lineHeight: "32px",
    pointerEvents: "none",
    userSelect: "none",
  },
  table: {
    flex: 1,
  },
});

function getStockItemById(aggregatedStock, id) {
  for (const { stockItems } of aggregatedStock) {
    for (const stockItem of stockItems) {
      if (stockItem.id === id) {
        return stockItem;
      }
    }
  }
}

export const defaultQuickFilters = [
  {
    id: "hideEmptyStockItems",
    label: ({ t }) => t("Leere Bestände ausblenden"),
    filter: ({ row, getStock }) => getStock(row) > 0,
  },
];

class Stock extends React.PureComponent {
  state = {
    selectedItem: null,
    updateItemDialogOpen: false,
    anchorEl: null,
    actionMenuOpen: false,
    csvExportDialogOpen: false,
    aggregateExport: false,
    search: null,
    activeQuickFilters: JSON.parse(
      localStorage.getItem("skl-stockTable-quickfilters") || "{}"
    ),
  };

  searchRef = createRef();

  stockApi = {
    setSearchInput: (value) => {
      this.searchRef.current.setValue(value);
    },
  };

  handleRowClick = (e, rowId, row) => {
    const articleActions = this.props.registry.getArticleActions(
      this.props.registry.stockItemTypes[this.props.type].articleType
    );
    if (
      row.__typename === "AggregatedStockItems" &&
      articleActions.length === 0 &&
      this.props.registry.stockItemTypes[this.props.type].stockActionsConfig
        ?.canCreateStockItems === false
    ) {
      // no actions so don't display the menu
      return;
    }

    // every stock item has multiple actions (at least change stock and change location)
    // const actions = this.props.registry.getStockActions(stockItem.type);
    const hasMultipleActions = true; // actions.length > 0;
    const actionMenuPosition = {
      top: e.clientY,
      left: e.clientX,
    };
    this.setState({
      anchorEl: document.querySelector(`[data-row-id='${rowId}']`),
      selectedItem: row,
      updateItemDialogOpen: !hasMultipleActions,
      action: hasMultipleActions ? null : "changeStock",
      actionMenuOpen: hasMultipleActions,
      actionMenuPosition,
    });
  };

  handleItemAction = (action) => (e) => {
    if (action === "changeStock" || action.PopoverForm) {
      this.setState({
        updateItemDialogOpen: true,
        action,
        // sometimes the table rows re-render between the context menu opening and the action being clicked, so update the anchor element here
        anchorEl: document.querySelector(
          `[data-row-id='${this.state.selectedItem.id}']`
        ),
      });
    } else if (action === "hideItem") {
      this.handleHideItem(this.state.selectedItem);
    }
    this.setState({ actionMenuOpen: false });
  };

  handleCreateItem = async (article, initialStock, location, note) => {
    const { t } = this.props;
    try {
      const actualInitialStock =
        this.props.registry.stockItemTypes[
          this.props.type
        ].stockInfo.deltaToActual?.(initialStock, article) ?? initialStock;
      await this.props.onCreateItem(
        article.id,
        {
          initialStock: actualInitialStock,
          loadCarrierId: location?.loadCarrier,
          compartmentId: location?.compartment,
          note,
        },
        note
      );
      this.handleHidePopover();
      this.props.snackbar.showMessage(
        t("Die {{type}}-Einheit wurde hinzugefügt", {
          type: this.props.registry.stockItemTypes[this.props.type].displayName(
            1,
            { t }
          ),
          context: this.props.type,
        })
      );
    } catch (e) {
      console.error(e);
      this.props.snackbar.showMessage(
        t("Die {{type}}-Einheit konnte nicht hinzugefügt werden werden", {
          type: this.props.registry.stockItemTypes[this.props.type].displayName(
            1,
            { t }
          ),
          context: this.props.type,
        })
      );
    }
  };

  handleUpdateItem = async (item, { delta, note }) => {
    const { t } = this.props;
    try {
      await this.props.onUpdateItem(item, { delta, note });
      this.handleHidePopover();
      this.props.snackbar.showMessage(
        t("Der Bestand wurde aktualisiert"),
        t("Rückgängig"),
        async () => {
          try {
            await this.props.onUpdateItem(item, { delta: -delta });
          } catch (e) {
            console.error(e);
            this.props.snackbar.showMessage(
              t("Die Bestandsänderung konnte nicht rückgängig gemacht werden")
            );
          }
        }
      );
    } catch (e) {
      console.error(e);
      this.props.snackbar.showMessage(
        t("Der Bestand konnte nicht aktualisiert werden")
      );
    }
  };

  handleHideItem = async (item) => {
    const { t } = this.props;
    try {
      await this.props.onHideStockItem(item);
      this.props.snackbar.showMessage(
        t("Die {{type}}-Einheit wurde entfernt", {
          type: this.props.registry.stockItemTypes[this.props.type].displayName(
            1,
            { t }
          ),
          context: this.props.type,
        })
      );
    } catch (e) {
      console.error(e);
      this.props.snackbar.showMessage(
        t("Die {{type}}-Einheit konnte nicht entfernt werden", {
          type: this.props.registry.stockItemTypes[this.props.type].displayName(
            1,
            { t }
          ),
          context: this.props.type,
        })
      );
    }
  };

  handleHidePopover = () =>
    this.setState({
      updateItemDialogOpen: false,
      actionMenuOpen: false,
      csvExportDialogOpen: false,
    });

  handleSaveNote = async (e, { note, noteIndex, id, type }) => {
    const { t } = this.props;
    try {
      let previousNote;
      if (type === "stockItem") {
        const item = getStockItemById(this.props.stock, id);
        await this.props.onUpdateStockItemNote(item, noteIndex, note);
        previousNote =
          item[`note${noteIndex === 0 ? "" : noteIndex + 1}`]?.text ?? "";
      } else if (type === "article") {
        const item = this.props.stock.find((row) => row.article.id === id);
        await this.props.onUpdateArticleNote(item, noteIndex, note);
        previousNote =
          item.article[`note${noteIndex === 0 ? "" : noteIndex + 1}`]?.text ??
          "";
      } else {
        return;
      }
      this.props.snackbar.showMessage(
        note === ""
          ? t("Die Notiz wurde entfernt")
          : t("Die Notiz wurde aktualisiert"),
        t("Rückgängig"),
        async () => {
          try {
            if (type === "stockItem") {
              const item = getStockItemById(this.props.stock, id);
              await this.props.onUpdateStockItemNote(
                item,
                noteIndex,
                previousNote
              );
            } else if (type === "article") {
              const item = this.props.stock.find(
                (row) => row.article.id === id
              );
              await this.props.onUpdateArticleNote(
                item,
                noteIndex,
                previousNote
              );
            }
          } catch (e) {
            console.error(e);
            this.props.snackbar.showMessage(
              t("Die Notizänderung konnte nicht rückgängig gemacht werden")
            );
          }
        }
      );
    } catch (e) {
      console.error(e);
      this.props.snackbar.showMessage(
        t("Die Notiz konnte nicht gespeichert werden")
      );
    }
  };

  handleSaveNoteColumnTitle = async (e, { noteIndex, title, type }) => {
    const { t } = this.props;
    try {
      if (type === "stockItem") {
        await this.props.onUpdateStockItemNoteColumnTitle(noteIndex, title);
      } else if (type === "article") {
        await this.props.onUpdateArticleNoteColumnTitle(noteIndex, title);
      }
    } catch (e) {
      console.error(e);
      this.props.snackbar.showMessage(
        t("Der Spaltentitel konnte nicht gespeichert werden")
      );
    }
  };

  getColumns = () => {
    const {
      registry,
      articleNoteColumns,
      stockItemNoteColumns,
      type,
      t,
      features,
    } = this.props;

    return [
      ...registry.getStockTableConfig(type, { t }),
      compartmentColumn({ t }),
      towerColumn({ t }),
      features.loadCarriers ? loadCarrierColumn({ t }) : undefined,
      {
        id: "temporaryLocation",
        title: t("Temporärer Lagerort"),
        contentString: (row) => {
          if (row.temporaryLocation) {
            let content = row.temporaryLocation;
            if (row.temporaryCompartment) {
              content += `, ${row.temporaryCompartment}`;
            }
            return content;
          }
          return row.temporaryCompartment || "";
        },
        compare: emptyToBottom(
          maybeAggregated((row) => {
            if (row.temporaryLocation) {
              let content = row.temporaryLocation;
              if (row.temporaryCompartment) {
                content += `, ${row.temporaryCompartment}`;
              }
              return content;
            }
            return row.temporaryCompartment || "";
          })
        ),
        filter: {
          type: StringSearchFilter,
        },
      },
      articleNoteColumn(
        {
          id: "note",
          title: articleNoteColumns?.[0],
          note: (row) => row.article.note,
          noteIndex: 0,
        },
        { t }
      ),
      articleNoteColumn(
        {
          id: "note2",
          title: articleNoteColumns?.[1],
          note: (row) => row.article.note2,
          noteIndex: 1,
        },
        { t }
      ),
      articleNoteColumn(
        {
          id: "note3",
          title: articleNoteColumns?.[2],
          note: (row) => row.article.note3,
          noteIndex: 2,
        },
        { t }
      ),
      stockItemNoteColumn(
        {
          id: "stockItemNote",
          title: stockItemNoteColumns?.[0],
          noteIndex: 0,
        },
        { t }
      ),
      stockItemNoteColumn(
        {
          id: "stockItemNote2",
          title: stockItemNoteColumns?.[1],
          noteIndex: 1,
        },
        { t }
      ),
      stockItemNoteColumn(
        {
          id: "stockItemNote3",
          title: stockItemNoteColumns?.[2],
          noteIndex: 2,
        },
        { t }
      ),
    ].filter((column) => !!column);
  };

  stockItemToStrings = (item, columns, rawValue = true) =>
    columns
      .filter((c) => c.export == null || !c.export.exclude)
      .map((c) => {
        if (rawValue !== false && c.export && c.export.getRawValue) {
          return c.export.getRawValue(item);
        }
        if (c.contentString) {
          return c.contentString(item);
        } else if (c.content) {
          return c.content(item);
        } else {
          return item[c.id];
        }
      })
      .map((value) => (value == null ? "" : value.toString()));

  stockItemToExcel = (item, columns) =>
    columns
      .filter((c) => c.export == null || !c.export.exclude)
      .map((c) => {
        if (c.export && c.export.getRawValue) {
          return c.export.getRawValue(item);
        }
        if (c.contentString) {
          return c.contentString(item);
        } else if (c.content) {
          return c.content(item);
        } else {
          return item[c.id];
        }
      })
      .map((value) => (value == null ? "" : value));

  handleExport = async (columnIds, filename, format) => {
    this.handleHidePopover();

    const { stock } = this.props;
    const columns = this.getColumns().filter(
      (c) => columnIds.includes(c.id) && (c.export == null || !c.export.exclude)
    );

    if (format === "csv") {
      const stockRows = this.state.aggregateExport
        ? stock.map((item) =>
            item.stockItems.length === 1
              ? this.stockItemToStrings(item.stockItems[0], columns)
              : this.stockItemToStrings(item, columns)
          )
        : stock
            .flatMap((item) => item.stockItems)
            .map((item) => this.stockItemToStrings(item, columns));

      saveCsv(
        [columns.map((c) => c.export?.title ?? c.title), ...stockRows],
        filename
      );
    } else if (format === "xlsx") {
      const stockRows = this.state.aggregateExport
        ? stock.map((item) =>
            item.stockItems.length === 1
              ? this.stockItemToExcel(item.stockItems[0], columns)
              : this.stockItemToExcel(item, columns)
          )
        : stock
            .flatMap((item) => item.stockItems)
            .map((item) => this.stockItemToExcel(item, columns));

      saveXlsx(
        [columns.map((c) => c.export?.title ?? c.title), ...stockRows],
        filename,
        this.props.t("Bestand")
      );
    }
  };

  handleChangeSearch = debounce((search) => {
    this.setState({ search });
  }, 100);

  handleToggleQuickFilter = (filterId) => () => {
    const activeQuickFilters = {
      ...this.state.activeQuickFilters,
    };
    if (filterId in activeQuickFilters) {
      delete activeQuickFilters[filterId];
    } else {
      activeQuickFilters[filterId] = true;
    }
    this.setState({ activeQuickFilters });
    localStorage.setItem(
      "skl-stockTable-quickfilters",
      JSON.stringify(activeQuickFilters)
    );
  };

  applyQuickFilters = (row) => {
    const quickFilters = this.props.quickFilters || defaultQuickFilters;
    const stockItemType = this.props.registry.stockItemTypes[this.props.type];
    return quickFilters
      .filter((filter) => this.state.activeQuickFilters[filter.id])
      .every((filter) =>
        filter.filter({
          row,
          getStock:
            stockItemType.stockInfo.calculateStock ??
            ((item) => item.initial + item.in - item.out),
        })
      );
  };

  getFilteredStockItems = (columns) => {
    const { search } = this.state;
    const { filters, searchTerms } = prepareSmartSearch(search);

    return (row) => {
      if (!this.applyQuickFilters(row)) {
        return false;
      }

      if (filters.some(({ isMatch }) => !isMatch(row))) {
        return false;
      }

      if (this.props.features.strictStockSearch) {
        // at least one column must contain all search terms
        return this.stockItemToStrings(row, columns, false).some((value) =>
          searchTerms.every(
            (s) => value.toString().toLowerCase().indexOf(s.toLowerCase()) >= 0
          )
        );
      } else {
        // every search term must be contained in at least one column
        return searchTerms.every((s) =>
          this.stockItemToStrings(row, columns, false).some(
            (value) =>
              value.toString().toLowerCase().indexOf(s.toLowerCase()) >= 0
          )
        );
      }
    };
  };

  render() {
    const {
      classes,
      stock,
      articleNoteColumns,
      stockItemNoteColumns,
      type,
      registry,
      error,
      loading,
      t,
      features,
      quickFilters = defaultQuickFilters,
    } = this.props;

    const {
      anchorEl,
      action,
      selectedItem,
      updateItemDialogOpen,
      actionMenuOpen,
      actionMenuPosition,
      csvExportDialogOpen,
      aggregateExport,
      activeQuickFilters,
    } = this.state;

    const columns = this.getColumns({
      t,
      registry,
      type,
      articleNoteColumns,
      stockItemNoteColumns,
    });

    const stockItemType = this.props.registry.stockItemTypes[type];
    const stockActions = this.props.registry.getStockActions(type);
    const articleActions = this.props.registry.getArticleActions(
      stockItemType.articleType
    );
    const getStock =
      stockItemType.stockInfo.calculateStock ??
      ((item) => item.initial + item.in - item.out);

    const createStockItemAction = {
      PopoverForm: ({ item }) => (
        <CreateStockItemForm item={item} onSubmit={this.handleCreateItem} />
      ),
    };

    return (
      <div className={classes.root}>
        <div className={classes.toolbar}>
          <SearchTextField
            innerRef={this.searchRef}
            onChange={this.handleChangeSearch}
            placeholder={t("Suchen…")}
            className={classes.search}
          />
          <div className={classes.flex} />
          <ComponentExtensionPoint name="stockTableToolbar" variant="list" />
          {quickFilters.length > 0 && (
            <PopoverIconButton
              className={classes.toolbarButton}
              icon={
                Object.keys(activeQuickFilters).length > 0
                  ? Filter
                  : FilterOutline
              }
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              tooltip={t("Schnellfilter")}
              color={
                Object.keys(activeQuickFilters).length > 0
                  ? "primary"
                  : "default"
              }
            >
              <MenuList dense>
                {quickFilters.map((filter) => (
                  <MenuItem
                    key={filter.id}
                    onClick={this.handleToggleQuickFilter(filter.id)}
                    dense
                  >
                    <ListItemIcon>
                      <Check
                        style={{
                          opacity: activeQuickFilters[filter.id] ? 1 : 0,
                        }}
                      />
                    </ListItemIcon>
                    <Typography variant="inherit">
                      {filter.label({ t })}
                    </Typography>
                  </MenuItem>
                ))}
              </MenuList>
            </PopoverIconButton>
          )}
          <TooltipIconButton
            tooltip={t("Exportieren")}
            className={classes.toolbarButton}
            onClick={(e) => {
              this.setState({
                csvExportDialogOpen: true,
                anchorEl: e.target,
              });
            }}
          >
            <TableArrowDown />
          </TooltipIconButton>
          {registry.getStockToolbarActions(type).map((action) => {
            const label = action.label({ t });
            const Button = ({ onClick }) => (
              <TooltipIconButton
                tooltip={label}
                className={classes.toolbarButton}
                onClick={onClick}
              >
                <action.Icon />
              </TooltipIconButton>
            );
            return (
              <action.Component
                key={label}
                Button={Button}
                stockApi={this.stockApi}
              />
            );
          })}
        </div>
        <StockTable
          key={type}
          className={classes.table}
          localStorageKey={`skl-sockTable-${type}`}
          columns={columns}
          data={loading ? null : stock}
          nestedRowsField="stockItems"
          flattenSingleItemGroups
          rowFilter={this.getFilteredStockItems(columns)}
          order="asc"
          orderBy="sku"
          onRowClick={this.handleRowClick}
          rowId={(row) => row.id}
          rowRenderer={({ key, ...props }, { row, nestedOf }) => (
            <ComponentExtensionPoint
              key={key}
              name="stockTableRow"
              innerProps={{ ...props, row, nestedOf }}
              InnerComponent={({ row, nestedOf, ...props }) => (
                <TableRow
                  {...props}
                  data-row-id={row.id}
                  className={cx(props.className, {
                    [classes.minimumStockRow]:
                      (nestedOf == null || nestedOf.flattened) && // nur in aggregierten Zeilen anzeigen
                      row.article.type === type &&
                      row.article.minimumStock > 0 &&
                      getStock(row) <= row.article.minimumStock,
                  })}
                />
              )}
              variant="nested"
            />
          )}
          cellContentParams={{ handleSaveNote: this.handleSaveNote }}
          headerActionsParams={{
            handleSaveNoteColumnTitle: this.handleSaveNoteColumnTitle,
          }}
          size="small"
          EnhancedTableHeadProps={{ size: "medium" }}
          disableLineWrap
          maxColumnWidth={170 + 32}
          highlightedRows={
            actionMenuOpen || updateItemDialogOpen
              ? [selectedItem.id]
              : undefined
          }
          placeholder={
            <TablePlaceholder>
              {error ? (
                <>
                  {t("Beim Laden des Bestands ist ein Fehler aufgetreten.")}
                  <br />
                  <br />
                  {error.graphQLErrors?.length === 0 &&
                    t("Bitte überprüfen Sie Ihre Internetverbindung.")}
                </>
              ) : type === registry.stockItemTypes[type].articleType ? (
                <>
                  <span>
                    {t("Es gibt keine aktiven {{type}}-Artikel.", {
                      type: registry.stockItemTypes[type].displayName(1, { t }),
                      context: type,
                    })}
                    <br />
                    <br />
                    {t(
                      "Wechseln Sie in die Artikel-Ansicht, um neue Artikel anzulegen. Anschließend können die Bestände hier verwaltet werden."
                    )}
                  </span>
                </>
              ) : (
                <span>
                  {t("Es gibt derzeit keine {{type}}.", {
                    type: registry.stockItemTypes[type].displayName(2, { t }),
                  })}
                  <br />
                  <br />
                  {t(
                    "Wechseln Sie in die Bestands-Ansicht, um neue {{type}} anzulegen.",
                    {
                      type: registry.stockItemTypes[type].displayName(2, { t }),
                    }
                  )}
                </span>
              )}
            </TablePlaceholder>
          }
        />
        <Menu
          open={actionMenuOpen}
          onClose={this.handleHidePopover}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          anchorReference="anchorPosition"
          anchorPosition={
            actionMenuPosition
              ? {
                  top: actionMenuPosition.top - 24 - 16,
                  left: actionMenuPosition.left - 24,
                }
              : null
          }
        >
          <ListSubheader className={classes.menuHeader}>
            {[
              selectedItem?.article.name,
              selectedItem?.sku ?? selectedItem?.article.sku,
            ]
              .filter((text) => !!text?.trim())
              .join(" · ")}
          </ListSubheader>
          {selectedItem?.__typename === "StockItem" && (
            <MenuItem dense onClick={this.handleItemAction("changeStock")}>
              {t("Bestand verändern")}
            </MenuItem>
          )}
          {selectedItem?.__typename === "StockItem" &&
            stockActions.map((action) => {
              if (action.MenuItem) {
                return (
                  <action.MenuItem
                    key={
                      typeof action.label === "string"
                        ? action.label
                        : action.label({ t })
                    }
                    dense
                    item={selectedItem}
                    onClick={this.handleItemAction(action)}
                    onClose={this.handleHidePopover}
                  />
                );
              }
              return (
                <MenuItem
                  key={
                    typeof action.label === "string"
                      ? action.label
                      : action.label({ t })
                  }
                  dense
                  onClick={this.handleItemAction(action)}
                >
                  {typeof action.label === "string"
                    ? action.label
                    : action.label({ t })}
                  {action.Icon && (
                    <ListItemIcon style={{ position: "absolute", right: 0 }}>
                      <action.Icon fontSize="small" />
                    </ListItemIcon>
                  )}
                </MenuItem>
              );
            })}
          {selectedItem?.__typename === "StockItem" && <Divider />}
          {selectedItem?.__typename === "StockItem" && features.loadCarriers && (
            <MenuItem
              dense
              disabled={selectedItem?.loadCarrier == null}
              onClick={this.handleItemAction({
                PopoverForm: MoveLoadCarrierForm,
              })}
            >
              {t("Ladungsträger bewegen")}
            </MenuItem>
          )}
          {selectedItem?.__typename === "StockItem" && (
            <MenuItem
              dense
              onClick={this.handleItemAction({
                PopoverForm: ChangeLocationForm,
              })}
            >
              {features.loadCarriers
                ? t("Lagerort/Ladungsträger ändern")
                : t("Lagerort ändern")}
            </MenuItem>
          )}
          {selectedItem?.__typename === "StockItem" && (
            <MenuItem
              dense
              onClick={this.handleItemAction({
                PopoverForm: ChangeTemporaryLocationForm,
              })}
            >
              {t("Temporären Lagerort ändern")}
            </MenuItem>
          )}
          {selectedItem?.__typename === "StockItem" &&
            (registry.stockItemTypes[type].stockActionsConfig
              ?.canCreateStockItems !== false ||
              registry.stockItemTypes[type].stockActionsConfig
                ?.canHideStockItems !== false) && <Divider />}
          {registry.stockItemTypes[type].stockActionsConfig
            ?.canCreateStockItems !== false && (
            <MenuItem
              dense
              onClick={this.handleItemAction(createStockItemAction)}
            >
              {t("{{type}}-Einheit hinzufügen", {
                type: registry.stockItemTypes[type].displayName(1, { t }),
                context: type,
              })}
            </MenuItem>
          )}
          {registry.stockItemTypes[type].stockActionsConfig
            ?.canHideStockItems !== false &&
            selectedItem?.__typename === "StockItem" && (
              <MenuItem
                dense
                onClick={this.handleItemAction("hideItem")}
                disabled={getStock(selectedItem) > 0}
              >
                {t("{{type}}-Einheit entfernen", {
                  type: registry.stockItemTypes[type].displayName(1, { t }),
                  context: type,
                })}
              </MenuItem>
            )}
          {selectedItem?.__typename === "AggregatedStockItems" &&
            articleActions.length > 0 && <Divider />}
          {selectedItem?.__typename === "AggregatedStockItems" &&
            articleActions.map((action) => {
              if (action.MenuItem) {
                return (
                  <action.MenuItem
                    key={
                      typeof action.label === "string"
                        ? action.label
                        : action.label({ t })
                    }
                    dense
                    article={selectedItem.article}
                    onClick={this.handleItemAction(action)}
                    onClose={this.handleHidePopover}
                  />
                );
              }
              return (
                <MenuItem
                  key={
                    typeof action.label === "string"
                      ? action.label
                      : action.label({ t })
                  }
                  dense
                  onClick={this.handleItemAction(action)}
                >
                  {typeof action.label === "string"
                    ? action.label
                    : action.label({ t })}
                  {action.Icon && (
                    <ListItemIcon style={{ position: "absolute", right: 0 }}>
                      <action.Icon fontSize="small" />
                    </ListItemIcon>
                  )}
                </MenuItem>
              );
            })}
        </Menu>
        <Popover
          open={updateItemDialogOpen}
          onClose={this.handleHidePopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: classes.deltaPopover }}
          PaperProps={{
            component: DraggablePaper,
            DraggableProps: {
              handle: `.${classes.deltaPopover} > *:first-child`,
              defaultClassNameDragging: classes.popoverDragging,
            },
          }}
        >
          {selectedItem && action === "changeStock" && (
            <ChangeStockForm
              item={selectedItem}
              aggregatedItem={
                selectedItem != null
                  ? stock.find(
                      (aggregatedStockItem) =>
                        aggregatedStockItem.article.id ===
                        selectedItem.article.id
                    )
                  : null
              }
              onSubmit={this.handleUpdateItem}
            />
          )}
          {selectedItem && action && action !== "changeStock" && (
            <action.PopoverForm
              item={selectedItem}
              article={selectedItem.article}
              onClose={this.handleHidePopover}
            />
          )}
        </Popover>
        <Popover
          open={csvExportDialogOpen}
          onClose={this.handleHidePopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{ paper: classes.exportPopover }}
        >
          <TableExportForm
            columns={columns}
            onSubmit={this.handleExport}
            defaultFilename={t("Bestand {{type}} {{date}}", {
              type: registry.stockItemTypes[type].displayName(2, { t }),
              date: new Date().toLocaleDateString(),
            })}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={aggregateExport}
                  onChange={(e) =>
                    this.setState({ aggregateExport: e.target.checked })
                  }
                />
              }
              label={t("Zeilen nach Artikel gruppieren")}
              className={classes.aggregateExport}
            />
          </TableExportForm>
        </Popover>
      </div>
    );
  }
}

export default withTranslation()(
  withStyles(styles)(withRegistry()(withSnackbar()(Stock)))
);
